import React from 'react'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './all.sass'
import './global.scss'
import useSiteMetadata from './SiteMetadata'
import { WaypointContext } from '../components/waypoint'
import { AssetContext } from './asset'
import faviconURL from '../img/favicon.png'

const identity = (path: string) => path

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" className="has-navbar-fixed-top" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/bg1.jpg" />
        <link rel="icon" type="image/png" href={faviconURL} />
      </Helmet>
      <Navbar />
      <AssetContext.Provider value={identity}>
        <WaypointContext.Provider value="window">
          {children}
        </WaypointContext.Provider>
      </AssetContext.Provider>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
