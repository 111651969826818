import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import github from '../img/github-icon.svg'

const CompanyInfo = styled.div.attrs({ className: 'content has-text-centered' })`
  font-size: 13px;
  font-weight: 400;
  line-height: 1.85;
  color: #747481;
`

const Columns = styled.div`
  margin: 12px 0;
`

const Column = styled.div`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`

const Footer: FunctionComponent = () => {
  return (
    <footer className="footer has-background-black has-text-white-ter" style={{ minHeight: 200 }}>
      <CompanyInfo>
        <Columns>
          <Column>
            (주)스펙업애드
          </Column>
          <Column>
            사업자 등록번호 : 105-87-57696 | 대표이사: 박찬현
          </Column>
        </Columns>
        <Columns>
          <Column>
            서울특별시 강남구 역삼로 114 현죽빌딩 11층
          </Column>
          <Column>
            (교육사업본부, 출판팀, 경영지원본부)
          </Column>
        </Columns>
        <Columns>
          <Column>
            서울특별시 강남구 역삼로 135 신명빌딩 6층
          </Column>
          <Column>
            (매체운영팀, 광고사업팀, 기업부설연구소)
          </Column>
        </Columns>
      </CompanyInfo>
      <div className="content has-text-centered has-background-black has-text-white-ter">
        <div className="container has-background-black has-text-white-ter">
          <div className="columns" style={{ width: '100%' }}>
            <div className="column is-4 is-offset-8 social">
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
