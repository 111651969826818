import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetadata = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        markdownRemark(frontmatter: { templateKey: { eq: "seo" } }) {
          frontmatter {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )
  return markdownRemark.frontmatter
}

export default useSiteMetadata
