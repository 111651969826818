import React, { useCallback, useState } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import styled from 'styled-components'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import logo from '../img/logo.png'

const activeClassName = 'has-text-primary'

const NavLink = styled(Link).attrs({ activeClassName })``

const Navbar = () => {
  const [active, setActive] = useState(false)
  const toggleHamburger = useCallback(
    () => setActive(!active),
    [active],
  )

  return (
    <nav
      className="navbar is-transparent is-fixed-top"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Logo">
            <img src={logo} alt="Kaldi" style={{ width: '88px' }} />
          </Link>
          {/* Hamburger menu */}
          <div
            className={classNames('navbar-burger burger', { 'is-active': active })}
            data-target="navMenu"
            onClick={toggleHamburger}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div
          id="navMenu"
          className={classNames('navbar-menu', { 'is-active': active })}
        >
          <div className="navbar-end has-text-centered">
            <NavLink className="navbar-item" to="/about" partiallyActive>
              기업 소개
            </NavLink>
            <NavLink className="navbar-item" to="/service" partiallyActive>
              서비스
            </NavLink>
            <NavLink className="navbar-item" to="/interviews" partiallyActive>
              직원 인터뷰
            </NavLink>
            <NavLink className="navbar-item" to="/careers" partiallyActive>
              채용
            </NavLink>
            <OutboundLink className="navbar-item" href="https://medium.com/specupad" target="_blank">
              블로그
            </OutboundLink>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
