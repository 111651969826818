import React, { FunctionComponent, createContext, useContext } from 'react'
import { Waypoint as WaypointComponent } from 'react-waypoint'

export const WaypointContext = createContext(null)

export const Waypoint: FunctionComponent<WaypointComponent.WaypointProps> = props => {
  const scrollableAncestor = useContext(WaypointContext)

  return (
    <WaypointComponent
      scrollableAncestor={scrollableAncestor}
      {...props}
    />
  )
}

export default Waypoint
