import React, { FunctionComponent } from 'react'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Layout from '../components/Layout'

interface InterviewPostTemplateProps {
  content: any
  description: string
  tags: any[]
  title: string
  image: any
  helmet: any
}

const ImageContainer = styled.div`
  max-width: 600px;
  margin: auto;
`

const ContentContainer = styled.div`
  margin-top: 36px;
`

export const InterviewPostTemplate: FunctionComponent<InterviewPostTemplateProps> = ({
  content,
  description,
  tags,
  title,
  image,
  helmet,
}) => {
  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <ImageContainer>
              {image && <PreviewCompatibleImage imageInfo={{ image }} />}
            </ImageContainer>
            <ContentContainer>
              {content}
            </ContentContainer>
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

interface InterviewPostProps {
  data: any
}

const InterviewPost: FunctionComponent<InterviewPostProps> = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <InterviewPostTemplate
        content={
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        }
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | 인터뷰">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        image={post.frontmatter.image}
      />
    </Layout>
  )
}

export default InterviewPost

export const pageQuery = graphql`
  query InterviewPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 720, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
